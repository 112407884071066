.about {
  flex-grow: 1;
}

.about img {
  float: right;
  border-radius: 500px;
  width: 50%;
}

.about p {
  font-size: 20px;
  margin-bottom: 20px;
}