.gallery {
  flex-grow: 1;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .gallery {
    flex-direction: row;
    justify-content: space-between;
  }
}
