.contact {
  flex-grow: 1;
}

.contact iframe {
  border: 0;
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
  margin-top: 20px;
}

.contact p {
  font-size: 24px;
}

.contactinfo {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  margin-bottom: 15px;
}
